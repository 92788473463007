@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=REM:ital,wght@0,100..900;1,100..900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
@font-face {
  font-family: "Helvetica";
  src: url(./Helvetica_Rounded_LT_Std_Bold.otf);
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  font-family: "REM", sans-serif;
}
:root {
  font-size: 10px;
  --black: #000000;
  --white: #ffffff;
  --section-bg: #1a1c20;
}
html,
body {
  width: 100%;
  max-width: 100%;
}
.section {
  width: 100%;
}
a {
  text-decoration: none;
  cursor: pointer;
}
button {
  cursor: pointer;
}
.box {
  width: 100%;
  max-width: 1500px;
  padding: 0 10rem;
  margin: 0 auto;
}
.boogabera {
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
}
.boogabera-content {
  width: 100%;
  height: 100%;
  position: relative;
}
.boogabera-content img {
  width: 100%;
  height: 100%;
  object-position: top;
  object-fit: cover;
}
.boogabera-content .logo {
  width: 86px;
  height: auto;
  object-fit: contain;
  position: absolute;
  top: 1%;
  left: 1%;
  transform: translate(0%, 15%);
}
.boogabera-center-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.boogabera-content .logo-img {
  width: 135px;
  height: 135px;
  height: auto;
  object-fit: contain;
  position: absolute;
  top: 0%;
  left: 2%;
  transform: translate(0%, 0%);
}
.boogabera-content .top-button {
  border-radius: 54px;
  border: 6px solid #e0d571;
  background: linear-gradient(203deg, #524a44 -26.91%, #1c1a18 79.84%);
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.85);
  border-radius: 1000px;
  border: 6px solid #e0d571;
  color: #dfc545;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 3.25rem;
  font-size: 2rem;
  font-weight: 600;
  font-family: "Helvetica";
  cursor: pointer;
  letter-spacing: -0.64px;
  position: absolute;
  top: 5%;
  right: 2%;
  transform: translate(0%, 0%);
}
.boogabera-content .top-button:hover {
  background: linear-gradient(
    203deg,
    rgba(82, 74, 68, 0.9) -26.91%,
    rgba(28, 26, 24, 0.9) 79.84%
  );
}
.boogabera-center-cont .heading-img {
  width: 600px;
  height: auto;
  object-fit: contain;
  margin: 0 0 -12rem 0;
}
.boogabera-content .bear-img {
  width: 550px;
  height: unset;
  object-fit: contain;
  bottom: 0;
  left: 0;
  position: absolute;
}
.boogabera-center-cont h3 {
  font-size: 2.65rem;
  color: var(--white);
  font-weight: 900;
  margin: 0;
  text-align: center;
  letter-spacing: -1.75px;
}
.buttons-div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}
.mint-btn {
  border-radius: 54px;
  border: 6px solid #e0d571;
  background: linear-gradient(203deg, #524a44 -26.91%, #1c1a18 79.84%);
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.85);
  border-radius: 1000px;
  border: 6px solid #e0d571;
  color: #dfc545;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 5rem;
  font-size: 2.5rem;
  font-weight: 600;
  font-family: "Helvetica";
  cursor: pointer;
  letter-spacing: -0.64px;
}
.mint-btn:hover {
  background: linear-gradient(
    203deg,
    rgba(82, 74, 68, 0.9) -26.91%,
    rgba(28, 26, 24, 0.9) 79.84%
  );
}
.enter-btn {
  background: radial-gradient(
    194.87% 194.83% at 55.9% -76.72%,
    #ebb12e 1.68%,
    #f1ca5f 43.89%,
    #de7121 79%
  );
  box-shadow: 0px 0px 5px 0px rgba(255, 255, 255, 0.25),
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 1000px;
  border: 6px solid #383533;
  color: #262220;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 5rem;
  font-size: 2.5rem;
  font-weight: 600;
  font-family: "Helvetica";
  cursor: pointer;
  letter-spacing: -0.8px;
}
.enter-btn:hover {
  background: radial-gradient(
    194.87% 194.83% at 55.9% -76.72%,
    rgba(235, 178, 46, 0.8) 1.68%,
    rgba(241, 202, 95, 0.8) 43.89%,
    rgba(222, 112, 33, 0.8) 79%
  );
}
.social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3.5rem;
  margin: 1.5rem 0 0 0;
}
.social-icons svg {
  font-size: 2.65rem;
  color: var(--white);
  cursor: pointer;
  transition: 0.35s;
}
.social-icons svg:hover {
  transition: 0.35s;
  transform: translateY(-2px);
}

.boogabera-card-section {
  width: 100%;
  height: max-content;
  background-color: var(--section-bg);
  padding: 5rem 0 8rem 0;
}

.boogabera-card-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 2rem;
}

.linear-gradient {
  width: 100%;
  background: linear-gradient(360deg, #1a1c20 27.5%, rgba(26, 28, 32, 0) 93%);
  position: absolute;
  bottom: -15%;
  left: 0;
  height: 380px;
}

.boogabera-card-row1 {
  width: 100%;
  z-index: 10;
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr;
  gap: 2rem;
}

.boogabera-card-row1 > div,
.boogabera-card-row2 > div {
  background-color: #1e1f23;
  border-radius: 2rem;
  border: 1px solid #35383c;
  width: 100%;
}

.boogabera-card-row1 .boogabera-card-row1-col1 {
  color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  overflow: hidden;
  position: relative;
  z-index: 10;
  padding: 2rem 2rem 0 2rem;
}

.boogabera-card-row1-col1 h2 {
  font-size: 3rem;
  color: var(--white);
  font-weight: 600;
  letter-spacing: -0.65px;
}

.glow {
  width: 10rem;
  height: 30rem;
  background-color: #fbd069;
  filter: blur(100px);
  border-radius: 50%;
  margin: 0 0 -30rem 0;
  position: absolute;
  z-index: 0;
}
.circle-yellow {
  width: 28.5rem;
  height: 28.5rem;
  background-color: #fbd069;
  border-radius: 1000px;
  margin: 0 0 -9rem 0;
  border: 30px solid var(--white);
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.boogabera-card-row1-col1 img {
  width: auto;
  height: auto;
}

.boogabera-card-row1-col2,
.boogabera-card-row1-col3 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 5rem;
  padding: 2rem;
}

.boogabera-card-row1-col2 p,
.boogabera-card-row1-col3 p {
  color: var(--white);
  font-size: 1.65rem;
  font-weight: 300;
  margin: 1.2rem 0;
  letter-spacing: -0.36px;
}

.boogabera-card-row1-col2 h2,
.boogabera-card-row1-col3 h2 {
  color: #ffc22e;
  font-size: 2.35rem;
  font-weight: 500;
  width: 80%;
  line-height: 133%;
  letter-spacing: -0.48px;
}

.boogabera-card-row1-col3 > div {
  width: 100%;
}

.boogabera-card-row1-col2 img,
.boogabera-card-row1-col3 img {
  width: auto;
  height: auto;
  object-fit: contain;
}

.boogabera-card-row2 {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
}

.validators-section {
  width: 100%;
  height: max-content;
  background-color: #16181c;
  padding: 10rem 0;
  position: relative;
  overflow: hidden;
}
.vd-left {
  position: absolute;
  top: 0;
  right: 0;
  width: unset !important;
  height: unset !important;
  object-position: unset !important;
  object-fit: unset !important;
}
.vd-bottom {
  position: absolute;
  bottom: 0%;
  right: 0%;
  width: unset !important;
  height: unset !important;
  object-position: unset !important;
  object-fit: unset !important;
}
.vd-right {
  position: absolute;
  left: 0%;
  top: 0%;
  width: unset !important;
  height: unset !important;
  object-position: unset !important;
  object-fit: unset !important;
}
.validators-text h2 {
  color: var(--white);
  font-size: 5.65rem;
  font-weight: 600;
  margin: 0;
  letter-spacing: -1.75px;
}

.validators-text p {
  color: #bab7b7;
  font-size: 2.35rem;
  font-weight: 200;
  width: 80%;
  margin: 1rem 0 0 0;
  line-height: 175%;
  letter-spacing: -0.48px;
}

.validator-card {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  padding: 10rem 0 0 0;
  position: relative;
  z-index: 1;
}

.validator-card-item {
  background-color: #1e1f23;
  border-radius: 2rem;
  border: 1px solid #35383c;
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;
}

.validator-card-item-top {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5.5rem;
  border-bottom: 1px solid #35383c;
  padding: 0 0 3.5rem 0;
}

.validator-card-item-top img {
  width: 72px;
  height: 72px;
}

.validator-card-item-top-left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1.25rem;
}

.validator-card-item-top-left h3 {
  color: #ffc22e;
  font-size: 2.25rem;
  font-weight: 500;
  letter-spacing: -0.48px;
}

.validator-card-item-top-left p {
  color: #bab7b7;
  font-size: 1.65rem;
  font-weight: 400;
  letter-spacing: -0.32px;
  line-height: 170%;
}

.validator-card-item-bottom {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.25rem;
  padding: 0 3.25rem;
}

.validator-card-item-bottom button {
  padding: 1.2rem 2.5rem;
  border-radius: 1000px;
  background: transparent;
  font-family: "Helvetica";
  cursor: pointer;
}

.validator-card-item-bottom .btn2 {
  border: 2px solid #35383c;
  color: var(--white);
  font-size: 1.95rem;
  font-weight: 700;
  font-family: "Helvetica";
}

.validator-card-item-bottom .btn1 {
  border: 2px solid #e0d571;
  color: #e0d571;
  font-size: 1.95rem;
  font-weight: 700;
  font-family: "Helvetica";
  text-transform: uppercase;
}

.featured-section {
  width: 100%;
  height: max-content;
  background-color: #1a1c20;
  padding: 10rem 0;
}

.featured-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4.5rem;
}

.featured-container-item {
  width: 100%;
  display: grid;
  grid-template-columns: 1.15fr 1fr;
  gap: 2.45rem;
}

.featured-container-item-left-card {
  width: 100%;
  background-color: #1e1f23;
  border: 1px solid #35383c;
  border-radius: 2rem;
  padding: 0 3rem 0 6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  overflow: hidden;
}
.featured-container-item-left-card .honeypot-dots {
  position: absolute;
  bottom: 2%;
  right: 0;
  width: unset;
  height: unset;
}

.featured-container-item-left-card h2 {
  color: var(--white);
  font-size: 3rem;
  font-weight: 500;
  letter-spacing: -0.64px;
}

.featured-container-item-left-card p {
  color: #7e7e7e;
  font-size: 1.85rem;
  font-weight: 400;
  line-height: 1.8;
  letter-spacing: -0.4px;
  margin: 1.25rem 0 3.25rem 0;
}

.learn-more-btn {
  border-radius: 1000px;
  font-weight: 700;
  font-family: "Helvetica";
  background: radial-gradient(
    194.87% 194.83% at 55.9% -76.72%,
    #ebb12e 1.68%,
    #f1ca5f 43.89%,
    #de7121 79%
  );
  padding: 1.25rem 3.5rem;
  font-size: 1.85rem;
  border: 8px solid #383533;
}

.featured-container-item-right-card {
  position: relative;
  width: 100%;
  background: linear-gradient(to right, #28190a 0%, #efbe57 100%);
  height: 100%;
  border-radius: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.featured-container-item-right-card .blurred-background {
  position: absolute;
  top: 68px;
  left: 160px;
  width: 100%;
  height: 83%;
  overflow: hidden;
  z-index: 1;
}

.featured-container-item-right-card .blurred-background img {
  height: 100%;
  object-fit: cover;
  filter: blur(10px);
  width: auto;
}

.featured-container-item-right-card .foreground img {
  width: auto;
  height: 100%;
}

.featured-container-item-reverse {
  grid-template-columns: 1fr 691px;
}

.featured-container-item-reverse .featured-container-item-left-card {
  order: 2;
}

.featured-container-item-reverse .featured-container-item-right-card {
  order: 1;
}

.featured-container-item-reverse
  .featured-container-item-right-card
  .blurred-background {
  height: 100%;
  width: auto;
  left: 172px;
  top: 7px;
}

.featured-container-item-reverse
  .featured-container-item-right-card
  .foreground {
  height: 100%;
}

.lair-section {
  width: 100%;
  height: max-content;
  padding: 10rem 0 0;
  background-color: #16181c;
  position: relative;
}

.lair-section-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 90;
  position: relative;
}

.lair-section-container h2,
.the-warden-container h2 {
  color: var(--white);
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: -0.64px;
}

.lair-section-container p,
.the-warden-container p {
  color: #bab7b7;
  font-size: 2.25rem;
  font-weight: 300;
  text-align: center;
  width: 80%;
  line-height: 175%;
  margin: 1.25rem 0 4rem 0;
  letter-spacing: -0.48px;
}

.lair-section-bears {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -15rem 0 0 0;
  overflow: hidden;
}

.lair-section-bears .bear-img {
  width: 100%;
  position: relative;
  text-align: center;
  margin: 0 0 -5rem 0;
}

.lair-section-bears .bear-img img {
  height: 100%;
  width: 60rem;
}

.lair-section-bears .bear-img button {
  background-color: #16181c;
  border-radius: 1.2rem;
  color: var(--white);
  border: none;
  padding: 3rem 5rem;
  position: absolute;
  z-index: 80;
  bottom: 8%;
  left: 50%;
  transform: translateX(-50%);
  font-family: "REM";
  font-size: 2.2rem;
  font-weight: 1;
  letter-spacing: -0.48px;
  font-weight: 700;
}
.lair-section-bears .bear-img.bi-img button {
  margin: 0 0 2rem 0;
}

.bear-img .dotted-line {
  position: absolute;
  z-index: 71;
  bottom: 17%;
  left: 50%;
  width: 100%;
}
.black-linear {
  position: absolute;
  z-index: 71;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 20rem;
  background: linear-gradient(
    0deg,
    rgba(26, 28, 32, 1) 0%,
    rgba(26, 28, 32, 0) 100%
  );
}

.the-warden-section {
  width: 100%;
  height: max-content;
  padding: 10rem 0;
  background-color: #1a1c20;
}

.the-warden-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.warden-left-dots {
  position: absolute;
  left: -10%;
  top: -5%;
  width: unset !important;
  height: unset !important;
}
.warden-right-dots {
  position: absolute;
  right: -12%;
  bottom: -30%;
  width: unset !important;
  height: unset !important;
  z-index: 0;
}

.the-warden-container-cards {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.the-warden-container-card-item {
  width: 100%;
  background-color: #1e1f23;
  border: 1px solid #35383c;
  border-radius: 2rem;
  padding: 5rem 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.the-warden-container-card-item.left-card {
  padding: 5.5rem 6.5rem;
}

.the-warden-container-card-item h3 {
  color: var(--white);
  font-size: 2.8rem;
  font-weight: 700;
}

.the-warden-container-card-item p {
  text-align: left;
  width: 100%;
  color: #7e7e7e;
  font-size: 2rem;
}

.the-warden-container-card-item {
  width: 100%;
  position: relative;
  overflow: hidden;
  min-height: 40rem;
}

.the-warden-container-card-item img {
  object-fit: contain;
  width: auto;
}

.circle-glow {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -11%);
  width: 20%;
  height: 60rem;
  background-color: #fbd069;
  filter: blur(100px);
  border-radius: 50%;
}

.circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -33%);
  width: 48rem;
  height: 48rem;
  background-color: #fbd069;
  border: 30px solid var(--white);
  border-radius: 50%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

footer {
  width: 100%;
  height: max-content;
  padding: 7.5rem 0;
  background-color: #141517;
  position: relative;
  z-index: 1;
}

footer .footer-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-content-left {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4.65rem;
}

.footer-content-left img {
  width: 12%;
  height: auto;
}

.footer-content-left .links {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3.15rem;
}

.footer-content-left a {
  color: var(--white);
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: -0.32px;
}

.socials {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4rem;
}

.socials img {
  width: auto;
  height: auto;
}

@media only screen and (max-width: 1440px) {
  .boogabera-center-cont .heading-img {
    width: 500px;
    margin: -12rem 0 -12rem 0;
  }
}

@media only screen and (max-width: 1350px) {
  .boogabera-card-row1-col2,
  .boogabera-card-row1-col3 {
    gap: 2rem;
    padding: 1.15rem;
  }
  .boogabera-card-row1-col2 h2,
  .boogabera-card-row1-col3 h2 {
    font-size: 2rem;
  }
  .circle {
    transform: translate(-50%, -25%);
    width: 40rem;
    height: 40rem;
  }
  .the-warden-container-card-item.left-card {
    padding: 1.25rem 6.5rem;
  }
}

@media only screen and (max-width: 1150px) {
  .box {
    padding: 0 5rem;
  }
  .validator-card-item-bottom button {
    padding: 1.2rem 1.5rem;
  }
  .validator-card-item-bottom .btn1,
  .validator-card-item-bottom .btn2 {
    font-size: 1.5rem;
  }
  .featured-container-item {
    grid-template-columns: 1fr;
  }
  .featured-container-item-reverse {
    grid-template-columns: 1fr;
  }
  .featured-container-item-reverse .featured-container-item-left-card {
    order: 1;
  }
  .featured-container-item-reverse .featured-container-item-right-card {
    order: 2;
  }
  .featured-container-item-left-card {
    padding: 3rem 3rem 3rem 6rem;
  }
  .boogabera-card-row1 {
    grid-template-columns: 1.5fr 1fr;
  }
  .boogabera-card-row1-col3 {
    grid-column: span 2;
  }
}

@media only screen and (max-width: 1050px) {
  .validator-card-item-bottom .btn1,
  .validator-card-item-bottom .btn2 {
    font-size: 1.25rem;
  }
  .lair-section-bears .bear-img img {
    height: 100%;
    width: 50rem;
  }
  .lair-section-bears .bear-img button {
    padding: 1.85rem 3rem;
    font-size: 2rem;
  }
  .bear-img button {
    padding: 2.25rem 3rem;
  }
  .boogabera-center-cont {
    top: 60%;
  }
  .boogabera-center-cont .heading-img {
    width: 467px;
  }
  .boogabera-content .bear-img {
    width: 405px;
  }
  .lair-section-bears .bear-img {
    width: 100%;
  }
}

@media only screen and (max-width: 950px) {
  .validator-card {
    grid-template-columns: 1fr 1fr;
  }
  .circle {
    transform: translate(-50%, -11%);
    width: 32rem;
    height: 32rem;
  }
  .the-warden-container-card-item.left-card {
    padding: 1.25rem 3.5rem;
  }
  .the-warden-container-cards {
    flex-direction: column;
  }
  .footer-content-left {
    gap: 2.65rem;
  }
  .footer-content-left .links {
    gap: 2.15rem;
  }
  .socials {
    gap: 2.5rem;
  }
}

@media only screen and (max-width: 650px) and (min-width: 450px) {
  .bear-img .dotted-line {
    bottom: -23.5rem;
    left: 35%;
    width: 89%;
    transform: rotate(90deg);
  }
  .lair-section-bears .bear-img img {
    height: 50rem;
    width: 100%;
  }
  .lair-section-bears {
    flex-direction: column;
    margin: 0;
    gap: 5rem;
  }
  .bear-img button {
    padding: 1.25rem 2.5rem;
    transform: translateX(18%);
  }
}

@media only screen and (max-width: 650px) {
  .boogabera-center-cont .heading-img {
    width: 300px;
    margin: -7rem 0 -5rem 0;
  }
  .buttons-div {
    flex-direction: column;
    width: 100%;
    margin: 2rem 0 0 0;
  }
  .buttons-div button {
    width: 90%;
  }
  .boogabera-center-cont {
    width: 100%;
    padding: 0 2.5rem;
  }
  .boogabera-center-cont h3 br {
    display: none;
  }
  .boogabera-content .bear-img {
    width: 427px;
    left: -20%;
    bottom: -10%;
  }
  .boogabera-center-cont {
    transform: translate(-50%, -60%);
    gap: 1rem;
  }
  .boogabera-content .logo-img {
    width: 115px;
    top: 0%;
    left: -2%;
  }
  .boogabera-content .top-button {
    padding: 0.75rem 2rem;
    font-size: 1.85rem;
    top: 3%;
  }

  .box {
    padding: 0 2.5rem;
  }
  .boogabera-card-row1-col1 h2 {
    font-size: 2.25rem;
  }

  .validators-text p {
    width: 100%;
  }
  .validator-card {
    grid-template-columns: 1fr;
  }
  .validator-card-item-bottom {
    padding: 0 2.25rem;
  }
  .validator-card-item-bottom .btn1,
  .validator-card-item-bottom .btn2 {
    font-size: 1.5rem;
  }
  .validators-text h2 {
    font-size: 3.65rem;
  }
  .validators-text p {
    font-size: 2rem;
  }
  .validator-card {
    padding: 5rem 0 0;
  }

  .featured-container-item {
    grid-template-columns: 1fr;
  }
  .featured-container-item-reverse {
    grid-template-columns: 1fr;
  }
  .featured-container-item-reverse .featured-container-item-left-card {
    order: 1;
  }
  .featured-container-item-reverse .featured-container-item-right-card {
    order: 2;
  }
  .featured-container-item-left-card {
    padding: 2rem;
  }
  .boogabera-card-row1,
  .boogabera-card-row2 {
    grid-template-columns: 1fr;
  }
  .boogabera-card-row1-col3 {
    grid-column: span 1;
  }
  .circle-yellow {
    width: 26rem;
    height: 26rem;
  }
  .the-warden-container-cards {
    flex-direction: column;
  }
  .the-warden-container p {
    width: 100%;
  }
  .the-warden-container-card-item.left-card {
    padding: 3.5rem 2.5rem;
  }
  .the-warden-container-card-item img {
    transform: translate(0%, 4%);
  }
  .circle {
    width: 30rem;
    height: 30rem;
  }
  .lair-section-container p,
  .the-warden-container p {
    font-size: 1.85rem;
    width: 100%;
  }

  footer .footer-content {
    justify-content: flex-start;
    flex-direction: column;
    gap: 10rem;
  }
  .footer-content-left {
    flex-direction: column;
  }
  .footer-content-left img {
    width: 60%;
  }
  .footer-content-left .links {
    flex-direction: column;
  }
  .footer-content-left a {
    font-size: 2.25rem;
  }
  .vd-right {
    display: none;
  }
  .bear-circle {
    width: 400px !important;
    height: 400px !important;
  }
  .lair-section-bears .bear-img {
    width: 100%;
    height: 100%;
    bottom: unset;
    margin: 0;
  }
  .lair-section-bears .bear-img button {
    transform: translateX(-0%);
    font-size: 1.65rem;
    padding: 1.5rem 2rem;
  }
  .black-linear {
    bottom: -3px;
  }
  .bear-img .dotted-line {
    bottom: -45%;
    left: 20%;
    width: 100%;
    transform: rotate(90deg);
  }
}

@media only screen and (max-width: 450px) {
  .lair-section-bears {
    flex-direction: column;
    margin: 0;
    gap: 5rem;
  }
  .lair-section-bears .bear-img img {
    height: 100%;
    width: 100%;
  }
  .bear-img .dotted-line {
    bottom: -72px;
    left: 28%;
    width: 100%;
    transform: rotate(90deg);
  }
  .bear-img .dotted-line svg {
    width: 500px;
  }
}
